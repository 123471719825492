<template>
  <div class="">
    <b-card class="page-card" body-class="px-0">
      <div id="query-tool" :class="{ processing: fetchingFIs }">
        <div>
          <query-group
            :rules="queryRules"
            :query="query"
            @updateQuery="updateQuery"
            @clearResult="resetFICountAndPriceData"
          ></query-group>
          <div class="row">
            <div class="col-sm-8 offset-sm-2">
              <div class="row">
                <div class="col-sm-8 offset-sm-2">
                  <div class="row">
                    <div class="col-sm-12 pb-1">
                      <b-form-group>
                        <label for="Search" class="mb-1">
                          <b>Query Name</b
                          ><i class="pl-1">(required on save)</i>
                        </label>
                        <b-form-input
                          type="text"
                          name="query_name"
                          class="form-control rounded"
                          placeholder="Enter a Query Name"
                          v-model="queryName"
                          ref="queryName"
                          :class="[
                            $v.queryName.$dirty && $v.queryName.$invalid
                              ? 'is-invalid'
                              : '',
                            'form-control rounded'
                          ]"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          v-if="$v.queryName.$dirty && $v.queryName.$invalid"
                          >query name required to save</b-form-invalid-feedback
                        >
                        <div
                          class="invalid-feedback d-block info-msg"
                          v-if="showWarning"
                        >
                          <span class="fa fa-info-circle pr-1"></span>
                          changing name will save this as new group
                        </div>
                        <div
                          class="invalid-feedback d-block info-msg"
                          v-else-if="showUnSavedAlert"
                        >
                          <span class="fa fa-info-circle pr-1"></span>
                          changes not saved
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-8 offset-sm-2">
                  <b-form-group>
                    <label for="Search" class="mb-1">
                      <b>Description</b><i class="pl-1">(Optional)</i>
                    </label>
                    <b-form-textarea
                      id="description"
                      class="form-control rounded"
                      v-model="queryDescription"
                      rows="2"
                      max-rows="2"
                    >
                    </b-form-textarea>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-8 offset-sm-2">
                  <b-form-group>
                    <label for="Search" class="mb-1">
                      <b>Group Access</b><i class="pl-1">(Optional)</i>
                    </label>

                    <multiselect
                      v-model="selectedGroups"
                      :options="userGroups"
                      :multiple="true"
                      :close-on-select="false"
                      label="name"
                      track-by="id"
                      :showLabels="false"
                      placeholder
                      class="simple-select"
                      :searchable="false"
                    >
                      <template slot="selection" slot-scope="{ values }"
                        >{{ values.length }}
                        {{ values.length | pluralize('group') }}
                        selected</template
                      >
                      <template slot="option" slot-scope="group">
                        <div>
                          <input
                            type="checkbox"
                            :checked="isGroupSelected(group.option)"
                            class="mr-1"
                          />
                          {{ group.option.name }}
                        </div>
                      </template>
                    </multiselect>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-sm-12 d-flex justify-content-center">
              <div class="px-1" v-if="userLoggedIn">
                <button
                  type="button"
                  class="btn fw-btn btn-success rounded"
                  v-b-modal.savedQueriesList
                >
                  Load Query
                </button>
              </div>
              <div
                class="px-1 d-flex flex-column"
                v-if="userLoggedIn && !queryPendingExecution"
              >
                <div>
                  <button
                    type="button"
                    :class="['btn fw-btn btn-success rounded']"
                    @click="persistQuery()"
                    :disabled="isProcessingData"
                  >
                    Save Query
                  </button>
                </div>
              </div>
              <div
                class="px-1"
                v-if="!queryPendingExecution && canBeSavedAsPeerGroup"
              ></div>
              <div class="px-1">
                <button
                  type="button"
                  class="btn fw-btn btn-secondary rounded"
                  @click="resetQuery"
                >
                  Reset Query
                </button>
              </div>
              <div class="px-1">
                <button
                  type="button"
                  class="btn fw-btn btn-primary rounded"
                  @click="executeQuery"
                  :disabled="isProcessingData"
                >
                  Run Query
                </button>
              </div>
              <div class="px-1" v-if="fiCount">
                <router-link
                  class="btn fw-btn btn-primary rounded"
                  :to="{
                    path: 'report'
                  }"
                >
                  View Report
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <saved-queries-modal
        @resetQueryRules="resetQuery"
        @querySelected="loadSelectedQuery"
      ></saved-queries-modal>
      <user-authentication-modal
        :show="showAuthModal"
        @closeAuthModal="closeAuthModal"
        @loginSuccess="loginSuccess"
      ></user-authentication-modal>
    </b-card>
  </div>
</template>
<script>
// global
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { EventBus } from '@/plugins/events.js'
import { findDeep } from 'deepdash'
// ui components
import Multiselect from 'vue-multiselect'
import QueryGroup from '../components/QueryGroup'
import SavedQueriesModal from '../components/SavedQueriesModal'
import UserAuthenticationModal from '@/modules/core/components/UserAuthenticationModal'
// api
import advancedQueryAPI from '@/modules/advanced_query/api/advanced.query'
// helpers
import QueryTransformer from '../helpers/query.transformer.js'
//import QueryRetransformer from '../helpers/query.retransformer.js'
//utilities
import _ from 'lodash'
import deepClone from '@/utilities.js'
//validations
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AQQueryBuilderForMBC',
  components: {
    QueryGroup,
    Multiselect,
    SavedQueriesModal,
    UserAuthenticationModal
  },
  props: {
    query: {
      type: Object
    }
  },
  // created() {
  //   if (this.isMortgageLender) {
  //     this.queryName = this.isEditMode ? this.selectedQuery.name : null
  //     this.queryGroupIDs = this.isEditMode ? this.selectedQuery.group_ids : []
  //     this.queryDescription = this.isEditMode
  //       ? this.selectedQuery.description
  //       : null
  //     this.query = deepClone(this.baseQuery)
  //     this.setPersistedQuery(deepClone(this.baseQuery))
  //   }
  // },
  mounted() {
    if (this.isMortgageLender) {
      let vm = this

      EventBus.$on('countUpdated', ruleDetail => {
        let matchingRule = findDeep(
          vm.query.children,
          function(rule) {
            return rule.id === ruleDetail.id
          },
          { childrenPath: 'children' }
        )

        if (matchingRule) {
          let queryRule = matchingRule.value

          queryRule.loading = false
          queryRule.count = ruleDetail.count
        }
      })
    }
  },
  beforeRouteEnter(to, form, next) {
    next(vm => {
      if (vm.$route.query.validate) {
        vm.calculateQueryValidity()

        if (!vm.isValidQuery) {
          vm.$toasted.global.validation_error(
            'Invalid Query, please fill in blank sections.'
          )
        }
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    if (this.showAlert(to.name)) {
      return this.$dialog
        .confirm(
          {
            title: 'Alert',
            body: 'Query is not updated! Would you like to run it now?'
          },
          {
            okText: 'Run Query',
            cancelText: 'No'
          }
        )
        .then(() => {
          this.executeQuery()
        })
        .catch(() => {
          this.prepareExit()
          next()
        })
    }

    next()
  },
  data: function() {
    return {
      showAuthModal: false,
      setAsGlobal: true,
      saveAsPG: false,
      searchText: '',
      institutions: [],
      filteredInstitutions: [],
      selectedInstitution: {},
      loading: false,
      offset: 0,
      fiType: { bank: 'Bank', credit_union: 'CU' }
    }
  },
  computed: {
    ...mapState('AdvancedQuery', {
      fiCount: state => state.fiCount,
      baseQuery: state => state.baseQuery,
      individualCount: state => state.individualCount,
      queryRules: state => state.queryRules,
      fetchingFIs: state => state.fetchingFIs,
      isValidQuery: state => state.isValidQuery,
      selectedQuery: state => state.selectedQuery,
      fiResultType: state => state.fiResultType,
      finQuery: state => state.finQuery,
      persistedQuery: state => state.persistedQuery,
      selectedQueryNameAsInDB: state => state.selectedQueryNameAsInDB
    }),
    ...mapState('ReportWriter', {
      selectedReport: state => state.selectedReport
    }),
    ...mapState('Authentication', {
      userGroups: state => state.userGroups
    }),
    ...mapGetters('Authentication', ['currentUserEmail']),
    ...mapGetters('AdvancedQuery', ['isProcessingData', 'isMortgageLender']),
    ...mapFields('AdvancedQuery', {
      queryName: 'selectedQuery.name',
      queryGroupIDs: 'selectedQuery.group_ids',
      queryDescription: 'selectedQuery.description'
    }),
    selectedGroups: {
      get() {
        return this.userGroups.filter(ug => {
          return this.queryGroupIDs && this.queryGroupIDs.includes(ug.id)
        })
      },
      set(groups) {
        this.setQueryGroupIDs(groups.map(g => g.id))
      }
    },
    userLoggedIn() {
      return this.currentUserEmail
    },
    isEditMode() {
      return (
        this.selectedQuery.id &&
        this.selectedQuery.name.trim() === this.selectedQueryNameAsInDB.trim()
      )
    },
    showWarning() {
      return (
        this.selectedQuery.id &&
        this.selectedQuery.name.trim() !== this.selectedQueryNameAsInDB.trim()
      )
    },
    queryChanged() {
      return !_.isEqual(this.baseQuery, this.query)
    },
    queryPendingExecution() {
      return this.fiCount === null && this.queryChanged
    },
    validForSave() {
      return this.isValidQuery && !this.$v.queryName.$invalid
    },
    showUnSavedAlert() {
      return (
        this.isEditMode &&
        this.persistedQuery &&
        !_.isEqual(this.query, this.persistedQuery)
      )
    },
    canBeSavedAsPeerGroup() {
      return this.fiResultType && this.fiResultType != 'both'
    },
    emptyInstitutions() {
      return this.filteredInstitutions.length === 0
    },
    canShowPeerGroupSave() {
      return !this.queryPendingExecution && this.canBeSavedAsPeerGroup
    },
    peerGroupExists() {
      return (
        this.selectedQuery &&
        this.selectedQuery.id &&
        this.selectedQuery.peer_group &&
        this.selectedQuery.peer_group.id
      )
    },
    showSelectFi() {
      return this.canShowPeerGroupSave && this.saveAsPG && !this.setAsGlobal
    }
  },
  methods: {
    ...mapActions('AdvancedQuery', ['runQuery', 'fetchSubscription']),
    ...mapMutations('AdvancedQuery', [
      'resetQueryData',
      'setSavedQueries',
      'saveCurrentQuery',
      'setQueryValidity',
      'setSelectedQuery',
      'setQueryGroupIDs',
      'setPersistedQuery',
      'setProcessingStatus',
      'resetFICountAndPriceData'
    ]),

    toggleSetAsGlobal(event) {
      this.setAsGlobal = event
      this.selectedInstitution = {}
    },
    toggleSaveAsPGFlag(event) {
      this.saveAsPG = event
      if (this.saveAsPG) this.loadInitData()
    },
    getUserInstitutions() {
      this.offset = 0
      this.loading = true
      return this.$http
        .get('/api/advisors', {
          params: {
            search_text: this.searchText,
            offset: this.offset,
            fi_type: this.fiType[this.fiResultType]
          },
          handleErrors: true
        })
        .then(
          res => {
            this.institutions = res.data.banks
            this.filteredInstitutions = this.institutions
            this.loading = false
            this.$nextTick(function() {
              if (this.selectedInstitution && this.selectedInstitution.id) {
                let ref = this.$refs['inst_' + this.selectedInstitution.id]
                if (ref) {
                  ref[0].scrollIntoView({ behavior: 'smooth' })
                }
              }
            })
          },
          () => {
            this.loading = false
          }
        )
    },
    fetchMoreData(event) {
      let el = event.target
      if (Math.round(el.scrollTop) >= el.scrollHeight - el.offsetHeight) {
        if (this.offset < this.filteredInstitutions.length) {
          this.loading = true
          this.offset = this.filteredInstitutions.length
          this.$http
            .get('/api/advisors', {
              params: {
                search_text: this.searchText,
                offset: this.offset,
                fi_type: this.fiType[this.fiResultType]
              },
              handleErrors: true
            })
            .then(res => {
              this.institutions = res.data.banks
              this.filteredInstitutions.push(...this.institutions)
              this.$nextTick(function() {
                if (this.selectedInstitution && this.selectedInstitution.id) {
                  let ref = this.$refs['inst_' + this.selectedInstitution.id]
                  if (ref) {
                    ref[0].scrollIntoView({ behavior: 'smooth' })
                  }
                }
              })
            })
            .then(() => {
              this.loading = false
            })
        }
      }
    },
    loadInitData() {
      this.selectedInstitution = {}
      this.getUserInstitutions()
    },
    search: _.debounce(function(event) {
      this.searchInstitutions(event)
    }, 500),
    searchInstitutions: function(event) {
      let text = event.target.value.toLowerCase()
      this.searchText = text
      this.offset = 0
      this.filteredInstitutions = []
      this.selectedInstitution = {}
      this.getUserInstitutions()
    },
    selectInstitution: function(ins) {
      this.selectedInstitution = ins
    },
    executeQuery() {
      this.calculateQueryValidity()
      let oldFiType = this.fiResultType
      if (this.isValidQuery) {
        if (this.currentUserEmail) {
          return this.runQuery(this.query).then(() => {
            this.updateIndividualCount(this.query)
            if (this.fiResultType != oldFiType) this.loadInitData()
          })
        } else {
          this.showAuthModal = true
          this.$toasted.show(
            'You are not logged in, please login and continue.',
            { icon: 'chain-broken', type: 'error' }
          )
          return Promise.resolve('user not logged in')
        }
      } else {
        this.$toasted.global.validation_error(
          'Invalid Query, please fill in blank sections.'
        )
        return Promise.resolve('invalidQuery')
      }
    },
    calculateQueryValidity() {
      this.setQueryValidity(true)
      this.checkQueryValidity(this.query.children)
    },

    checkQueryValidity(queryRules) {
      for (let rule of queryRules) {
        if (rule.children) {
          this.checkQueryValidity(rule.children)
        } else {
          if (
            rule.value.length === 0 ||
            (rule.value.length > 0 &&
              [undefined, ''].includes(rule.value[0])) ||
            (Array.isArray(rule.value[0]) && !rule.value[0].length)
          ) {
            this.setQueryValidity(false)
            rule.error = true
          } else {
            if (rule.filterOn) {
              for (let filter of rule.filters) {
                if (
                  filter.value.length === 0 ||
                  (filter.value.length > 0 &&
                    [undefined, ''].includes(filter.value[0]))
                ) {
                  this.setQueryValidity(false)
                  rule.error = true
                  filter.error = true
                }
              }
            }
            rule.error = false
          }
        }
      }
    },
    updateIndividualCount(query) {
      if (query.type === 'group') {
        query.children.forEach(rule => {
          this.updateIndividualCount(rule)
        })
      } else {
        query.count = this.individualCount[query.id]
      }
    },
    updateQuery(query) {
      this.$emit('updateQuery', query)
      // this.query = query
      // this.resetFICountAndPriceData()
    },
    loadSelectedQuery() {
      this.$emit('loadSelectedQuery')
      // if (this.selectedQuery.peer_group.id) {
      //   this.toggleSaveAsPGFlag(true)
      //   this.setAsGlobal = this.selectedQuery.peer_group.is_global
      //   this.selectedInstitution = {
      //     id: this.selectedQuery.peer_group.institution_id
      //   }
      // } else {
      //   this.toggleSaveAsPGFlag(false)
      // }
      // this.query = new QueryRetransformer().retransform
      // this.resetFICountAndPriceData()

      // this.$nextTick(function() {
      //   this.executeQuery().then(() => {
      //     this.setPersistedQuery(deepClone(this.query))
      //   }, {})
      // })
      // this.fetchSubscription(this.selectedQuery.id, this.selectedReport.id)
    },
    resetQuery() {
      // this.query = deepClone(this.baseQuery)
      // this.setPersistedQuery(deepClone(this.baseQuery))
      // this.resetFICountAndPriceData()
      // this.resetQueryData()
      this.saveAsPG = false
      this.setAsGlobal = true
      this.searchText = ''
      this.selectedInstitution = {}
      this.$v.$reset()
      this.$emit('resetQuery')
    },
    persistQuery() {
      this.calculateQueryValidity()
      this.$v.$touch()

      if (!this.isValidQuery) {
        return this.$toasted.global.validation_error(
          'Invalid Query, please fill in blank sections.'
        )
      }

      if (this.validForSave) {
        this.fiCount
          ? this.saveOrUpdateQuery()
          : this.runQuery(this.query).then(() => {
              this.saveOrUpdateQuery()
            })
      }
    },
    saveOrUpdateQuery() {
      this.isEditMode ? this.updateSavedQuery() : this.saveQuery()
    },
    prepareExit() {
      this.saveCurrentQuery(this.query)
      this.calculateQueryValidity()
    },
    checkPeerGroupValues() {
      if (this.saveAsPG && !this.setAsGlobal && !this.selectedInstitution.id) {
        this.$toasted.show(
          'Please select an Institution to assign the Peer Group.',
          { icon: 'chain-broken', type: 'error' }
        )
        return false
      }
      return true
    },
    saveQuery() {
      if (!this.checkPeerGroupValues()) return
      this.setProcessingStatus(true)
      let query = new QueryTransformer(this.query).transformToSave

      return advancedQueryAPI
        .saveQuery(
          this.queryName,
          this.queryDescription,
          this.queryGroupIDs,
          query,
          this.fiResultType,
          this.saveAsPG,
          this.setAsGlobal,
          this.selectedInstitution.id
        )
        .then(res => {
          if (res && res.query) {
            this.setSelectedQuery(res.query)
            let msg =
              res.peer_group && res.peer_group.id
                ? 'Query and Peer Group successfully saved'
                : 'Query successfully saved.'
            this.$toasted.show(msg, {
              icon: 'user-circle',
              type: 'success'
            })
          }
          this.setPersistedQuery(deepClone(this.query))
          this.fetchSubscription()
        })
        .finally(() => {
          this.setProcessingStatus(false)
        })
    },
    updateSavedQuery() {
      if (!this.checkPeerGroupValues()) return
      this.setProcessingStatus(true)
      let query = new QueryTransformer(this.query).transformToSave

      return advancedQueryAPI
        .updateQuery(
          this.selectedQuery,
          query,
          this.fiResultType,
          this.saveAsPG,
          this.setAsGlobal,
          this.selectedInstitution.id
        )
        .then(res => {
          if (res && res.query) {
            this.$toasted.show('Query successfully updated.', {
              icon: 'user-circle',
              type: 'success'
            })
          }
          this.setPersistedQuery(deepClone(this.query))
          this.setProcessingStatus(false)
        })
    },
    routingWithinFINQuery(toPath) {
      return ['AQReportWriter', 'AQDownload'].includes(toPath)
    },
    showAlert(toPath) {
      return this.queryPendingExecution && this.routingWithinFINQuery(toPath)
    },
    loginSuccess() {
      this.closeAuthModal()
    },
    closeAuthModal() {
      this.showAuthModal = false
    },
    isGroupSelected(option) {
      return this.queryGroupIDs && this.queryGroupIDs.includes(option.id)
    }
  },
  validations: {
    queryName: {
      required
    }
  }
}
</script>
<style lang="scss" scoped>
.search-container--result {
  .list-group-item {
    padding: 0.25em 1em;
    border: none;
    cursor: pointer;
    &.selected {
      background-color: #f0f3f5;
    }
    &:hover {
      background-color: #f0f3f5;
    }
  }
  .list-group-item.disabled {
    cursor: not-allowed;
  }
}
.equal-width {
  width: 7rem;
}
</style>
<style lang="scss">
.btn-fi-secondary {
  color: #fff;
  background-color: #c0d0d9;
  border-color: #c0d0d9;
}
</style>
